@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=block");

@font-face {
  font-family: "PPMondwest-Regular";
  src: url("./fonts/PPMondwest-Regular.otf") format("opentype");
}

@font-face {
  font-family: "neuebit-bold";
  src: url("./fonts/neuebit-bold.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
}
